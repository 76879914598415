const TRACKING_CATEGORY={
    ADD_FILES:"Add_Files",
    DOWNLOAD_FILES:"Download_Files",
    FORM_STEP: "Form_Step",
    EMAIL_DETAILS: "Email_Details",
    NEW_LEAD: "New_Lead",
    LOGIN_OR_SIGNUP: "login or signup",
    CUSTOM_BRAND_EDITOR: "custom brand editor",
    PRICING: 'pricing',
    WORK_TYPE: 'work type',
    ORGANIC_VISITS: 'organic visits',
    UPSELL: 'upsell',
    DROPBOX_LEAD: 'dropbox lead',
    INACTIVE_SURVEY: 'inactive survey',
    LOGGING: 'logging',
    EXTENSION: 'extension'
};

const LOCAL_STORAGE_KEYS={
    EMAIL_FROM:"email_from",
    EMAIL_RECIPIENTS:"email_recipients",
    EMAIL_MESSAGE: "email_message",
    UPLOADED_FILES: "uploaded_files",
    IS_LOGGED_IN: 'is_logged_in',
    INACTIVE_SURVEY_SHOWED: 'times_inactive_survey_showed',
    COMPLETED_TRANSFERS: 'completed_transfers',
    ENGAGED_SURVEY_SHOWED: 'engaged_survey_showed',
    BRAND_CHANGED: 'brand-changed',
    OAUTH_RESULT: 'oauth-result',
    NEW_EXTENSION_POPUP_SHOWED: 'new-extension-popup-showed',
    CLICKED_ON_INSTALL_EXTENSION_CTA: 'clicked-on-install-extension-cta',
    EXTENSION_INSTALLED: 'extension-installed',
    EXTENSION_OPENED: 'extension-opened',
    ONBOARDING_TYPE: 'onboarding-type'
};

const SESSION_STORAGE_KEYS={
    JUMP_TO_EDITOR: 'jump_to-editor',
    USER_EMAIL: 'user-email',
    FILES_ADDED: 'files-added',
    COMPLETE_PURCHASE: 'complete-purchase',
    NEW_SIGNUP: 'new-signup',
    IS_EXTENSION_DROP_OFF: 'is-extension-drop-off',
};

const GA_CONVERSION_TYPES={
    VERIFICATION_AND_WORK_EMAIL:"verification_and_work_email",
    ENGAGED_USER:"engaged_user", //engaged user is a user who uploaded at least 1 file or entered his email
    ANSWERED_WORK:"answered_work",
    SIGNUP:"signup",
    SIGNUP_VISIT: 'signup_visit',
    EXTENSION_INSTALLED: 'extension_installed',
};

const TAB_NAMES={
    Brand_Your_Site: "Brand your site",
    Brand_Your_Email: "Brand your email",
    Generate_Link: "Generate Link",
    Send_Email: "Send Email",
};

const TAB_GROUPS={
    FORM: 'form',
    BRAND_CUSTOMIZATION: 'brand-customization'
};

const DEVICE_TYPES={
    DESKTOP: "desktop",
    MOBILE: "mobile" 
};

const CODE_VERIFICATION_STATUS={
    IDLE: '',
    CODE_SENT: 'code sent',
    START: 'start',
    IN_PROGRESS: 'in progress',
    SUCCESSFUL: 'successful',
    FAILED: 'failed',
}

const FORM_FOOTER_VIEW_STATE={
     EDIT_UPLOAD: "edit-upload",
     UPLOAD_IN_PROGRESS: "upload-in-progress",
     CANCEL_UPLOAD: "cancel-upload",
}

const PRICING_PACKAGE_CTA={
    BUY: 'Buy it',
    FREE: 'Start Free',
    CONTACT_US: 'Join Beta'
}

const UPSELL_REASONS={
    UPLOAD_LIMIT_EXCEEDED: 'upload-limit-exceeded',
    RECIPIENTS_NUMBER_EXCEEDED: 'recipients-number-exceeded',
    TRANSFER_LIMIT_EXCEEDED: 'transfer-limit-exceeded',
}

const VALIDATION_TOOLTIP_REASONS={
    NO_FILES_ADDED: 'no-files-added',
    NO_USER_EMAIL: 'no-user-email',
    NO_RECIPIENTS_EMAIL: 'no-recipients-email',
    USER_NOT_VERIFIED: 'user-not-verified',
}

const FEATURE_NAMES={
    UPLOAD_SIZE_LIMIT: 'upload_size_limit_bytes',
    RECIPIENTS_NUMBER_LIMIT: 'recipients_number_limit',
    BRAND_CUSTOMIZATION: 'brand_customization',
    TRANSFERS_PER_MONTH_LIMIT: 'transfers_per_month_limit',
    UNLIMITED_TRANSFERS: 9007199254740991,
}

const SELECTED_PACKAGE={
    PACKAGE_ID: 'package-id',
    PRICE: 'package-price',
    PAYMENT_SUCCEEDED: 'payment-succeeded',
    PaidPackage: 'paid-package',
}

const PACKAGE_PRICES={
    SMB: '9',
    BUSINESS: '12',
}

const CODE_VERIFICATION_METHODS={
    EMAIL: 'email',
    SMS: 'sms',
}

const SITE_TEMPLATE_DEFAULTS={
    TITLE: "Page Title",
    DESCRIPTION: "Page Tagline",
    TAGLINE: "Page Description",
}

const ORGANIC_SOURCES={
    SHARE_FILES_LINK: 'share-link',
    SHARE_FILES_CUSTOM_BRAND_LINK: 'share-link-custom'
}

const PRODUCT_TUTORIAL_STEPS={
    CHOOSE_TRANSFER_TYPE: 'choose-transfer-type',
    UPLOAD_FILES: 'upload-files',
    ADD_RECIPIENTS: 'add-recipients',
    CLICK_SEND: 'click-send',
    COPY_LINK: 'copy-link',
}  

const FORM_RESIZE_MODE={
    EXPANDED: 'expanded',
    MINIMIZED: 'minimized'
}

const ONBOARDING_TYPES={
    EXTENSION: 'extension',
    EXTENSION_GMAIL: 'extension-gmail'
}
export default { TRACKING_CATEGORY, LOCAL_STORAGE_KEYS, GA_CONVERSION_TYPES, TAB_NAMES, DEVICE_TYPES,
    CODE_VERIFICATION_STATUS, SESSION_STORAGE_KEYS, FORM_FOOTER_VIEW_STATE, PRICING_PACKAGE_CTA, UPSELL_REASONS,
    FEATURE_NAMES, SELECTED_PACKAGE, TAB_GROUPS, PACKAGE_PRICES, CODE_VERIFICATION_METHODS, SITE_TEMPLATE_DEFAULTS,
    ORGANIC_SOURCES, VALIDATION_TOOLTIP_REASONS , PRODUCT_TUTORIAL_STEPS, FORM_RESIZE_MODE, ONBOARDING_TYPES}