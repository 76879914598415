import React, { createContext, useContext, useReducer } from 'react';
import consts from './components/consts'

export const tabs = [
      {
        name: consts.TAB_NAMES.Generate_Link,
        tabGroup: consts.TAB_GROUPS.FORM,
        maxWidth: 500,
        defaultWidth: 418,
        minWidth: 415,
        enabled: true
    },
    {
      name: consts.TAB_NAMES.Send_Email,
      tabGroup: consts.TAB_GROUPS.FORM,
      maxWidth: 500,
      defaultWidth: 418,
      minWidth: 415,
      enabled: true
  },
    {
        name: consts.TAB_NAMES.Brand_Your_Site,
        tabGroup: consts.TAB_GROUPS.BRAND_CUSTOMIZATION,
        maxWidth: 500,
        defaultWidth: 418,
        minWidth: 415,
        enabled: true
    },
    {
        name: consts.TAB_NAMES.Brand_Your_Email,
        tabGroup: consts.TAB_GROUPS.BRAND_CUSTOMIZATION,
        maxWidth: 500,
        defaultWidth: 418,
        minWidth: 415,
        enabled: false
    },
];

export const initialState = {
backgroundColor: "#181C45",
backgroundImage: null,
siteTemplate: {
  title: consts.SITE_TEMPLATE_DEFAULTS.TITLE,
  description: consts.SITE_TEMPLATE_DEFAULTS.DESCRIPTION,
  oneLiner: consts.SITE_TEMPLATE_DEFAULTS.TAGLINE,
  backgroundColor: "#181C45",
  complementaryColor: "#13BCB4",
  backgroundImage: null,
  isDemoTemplate: false,
  coverImage: '/images/nycPreDefinedCoverPhoto.jpg',
  coverImageUri: null,
  isPreDefinedCoverPhoto: null,
  brandPreviewSnapshot: null,
},
emailTemplate: {
    senderName: "{ Richard }",
    title: "{ PiedPiper.com }",
    message: `A message from Richard:<br/> "(You're transfer message will be displayed here)"`,
    signature: " { Richard, Pied Piper CEO }",
    backgroundColor: "#FFFFFF	",
    backgroundImage: null,
},

tabs: tabs,
currentActiveTab: tabs[0],
applyInitialStyle: true,
showSlidingPanel: false,
senderEmail: {
  email: "",
  isValid: false,
  deviceType: ""
},
focusSenderEmail: false,
uploadedFilePreviews: {previewFiles: [], totalFiles: 0},
codeVerificationData: {
code: null,
status: '',
forceResend: false,
verificationMethod: consts.CODE_VERIFICATION_METHODS.EMAIL,
userPhone: null,
},
formData: {
  emailFrom: ''
},
user: {
  isLoggedIn: false,
  userId: "",
  userName: "",
  userEmail: "",
  hasPublishedTemplate: false,
  enabledFeatures: null,
  completedOnboarding: false,
  planInfo: null,
  trialInfo: null,
  deviceType: null,
  isUsingSafari: null, //Due to a safari bug we need to detect and exclude the feature to download all files in a zip when a user is using safari and the total files size > 500 MB (We need to create the files inside a service worker in this case)
  isExtensionInstalled: false,
  monthlyTransferCount: null,
  nextResetDate: null,
  initCompleted: false,
},
loading: false,
formFooterViewState: consts.FORM_FOOTER_VIEW_STATE.EDIT_UPLOAD,
transferType: null,
defaultLimits: {
  uploadLimitInBytes: 2 * 1024 * 1024 * 1025, //2GB
  recipientsNumber: 2,
  fileRetentionDays: 7,
},
upsellTooltip: {
  show: false,
  reason: "",
  currentLimit: null
},
validationTooltip: {
  show: false,
  reason: "",
  customTooltipStyle: null,
  verificationTooltip: {
    show: false
  }
},
showUserSettingsTooltip: false,
showEngagedSurveyModal: false,
productTutorial: {
  show: false,
  currentStep: null,
},
showCelebrationScreen: false,
formResizeMode: consts.FORM_RESIZE_MODE.EXPANDED,
uploadId: null,
uploadCompleted: false
};

export function reducer(state, action) {
  const { payload, type } = action;
  switch (type) {
    case 'BACKGROUND-COLOR-CHANGED':
      return {
        ...state,
        backgroundColor: payload,
        backgroundImage: null
      };
    case 'BACKGROUND-IMAGE-CHANGED':
        return {
          ...state,
          backgroundImage: payload,
          backgroundColor: null
        }; 
    case 'SITE-TEMPLATE-CHANGED':
    return {
      ...state,
      siteTemplate: payload
    };     
    case 'EMAIL-TEMPLATE-CHANGED':
            return {
                ...state,
                emailTemplate: payload
              };
    case 'ACTIVE-TAB-CHANGED':
            return {
                ...state,
                currentActiveTab: tabs.find(i => i.name === payload),
                applyInitialStyle: false
              };  
    case 'FILE-PREVIEW-ADDED':
      return {
          ...state,
          uploadedFilePreviews: payload
        };
    case 'SENDER-EMAIL-CHANGED':
      return {
          ...state,
          senderEmail: payload
        };
    case 'SHOW-SLIDING-PANEL-TOGGLE':
          return {
              ...state,
              showSlidingPanel: payload
            };  
    case 'FOCUS-SENDER-EMAIL-CHANGED':
      return {
          ...state,
          focusSenderEmail: payload
        };    
    case 'CODE-VERIFICATION-STATUS-CHANGED':
      return {
          ...state,
          codeVerificationData: payload
        };   
    case 'FORM-DATA-CHANGED':
      return {
          ...state,
          formData: payload
        }; 
    case 'USER-CHANGED':
      return {
          ...state,
      user: payload
    };
    case 'LOADING-CHANGED':
      return {
          ...state,
          loading: payload
        }; 
    case 'FORM-FOOTER-VIEW-STATE-CHANGED':
      return {
          ...state,
          formFooterViewState: payload
        }; 
    case 'TRANSFER-TYPE-CHANGED':
      return {
          ...state,
          transferType: payload
        }; 
    case 'UPSELL-TOOLTIP-CHANGED':
      return {
          ...state,
          upsellTooltip: payload
        }; 
    case 'SHOW-USER-SETTINGS-CHANGED':
      return {
          ...state,
          showUserSettingsTooltip: payload
        }; 
    case 'VALIDATION-TOOLTIP-CHANGED':
      return {
          ...state,
          validationTooltip: payload
        }; 
    case 'VERIFICATION-TOOLTIP-CHANGED':
      return {
          ...state,
          verificationTooltip: payload
        }; 
    case 'SHOW-ENGAGED-SURVEY-MODAL-CHANGED':
      return {
          ...state,
          showEngagedSurveyModal: payload
        }; 
    case 'PRODUCT-TUTORIAL-CHANGED':
    return {
        ...state,
        productTutorial: payload
      };  
    case 'SHOW-CELEBRATION-SCREEN-CHANGED':
      return {
          ...state,
          showCelebrationScreen: payload
        };  
    case 'FORM-RESIZE-MODE-CHANGED':
      return {
          ...state,
          formResizeMode: payload
        };   
    case 'UPLOAD-ID-CHANGED':
      return {
          ...state,
          uploadId: payload
        }; 
    case 'UPLOAD-COMPLETED-CHANGED':
      return {
          ...state,
          uploadCompleted: payload
        }; 
    default:
      return state;
  }
}

const StateContext = createContext(null);

export function StateProvider({ children, initialState, reducer }) {
  return (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </StateContext.Provider>
  );
}

export function useStateValue() {
  return useContext(StateContext);
}
