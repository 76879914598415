import { useEffect } from "react"
import '../styles/global.css'
import "regenerator-runtime/runtime.js";
import { Scrollbars } from 'react-custom-scrollbars';

import { StateProvider, initialState, reducer } from '../state';

export default function App({ Component, pageProps }) {
  useEffect(() => {
    if("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/downloadZipServiceWorker.js").then(
        function (registration) {
          console.log("Service Worker registration successful with scope: ", registration.scope);
        },
        function (err) {
          console.log("Service Worker registration failed: ", err);
        }
      );
    }
  }, [])

  return (
  <StateProvider initialState={initialState} reducer={reducer}>
    <Component {...pageProps} />
  </StateProvider>
  );
  }